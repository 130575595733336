<template>
  <div ref="playerContainer" />
</template>

<script>
import {ref, computed, watch, onMounted} from 'vue'
import Chimee from 'chimee'
import hls from 'chimee-kernel-hls'
import isMobile from 'is-mobile'

export default {
  props: {
    src: { type: [String, Number] },
    type: { type: String },
    poster: { type: String },
    onProgress: { type: Function },
    onInit: {type: Function},
    noControls: {type: Boolean, default: false},
  },
  setup(props) {
    const playerContainer = ref();
    const computedSrc = computed(() => props.src || '');
    let player;

    const initPlayer = async () => {
      if (!computedSrc.value) {
        return;
      }

      if (!player) {
        player = new Chimee({
          wrapper: playerContainer.value,
          src: computedSrc.value,
          poster: props.poster,
          controls: !props.noControls,
          noDefaultContextMenu: true,
          autoplay: false,
          autoload: true,
          preload: 'metadata',
          box: isMobile() ? 'native' : 'hls',
          kernels: {hls},
        });
        player.on('timeupdate', () => {
          props.onProgress && props.onProgress(player);
        });
        props.onInit && props.onInit(player);
      } else {
        player.load(computedSrc.value);
      }
    };

    onMounted(initPlayer);
    watch(computedSrc, initPlayer);

    return {
      playerContainer,
    };
  },
}
</script>

<style>

</style>