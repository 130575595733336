<template>
  <div class="flex flex-row items-center justify-between py-2 m-2 border-2 bg-gray-300 rounded-md">
    <span class="px-2">{{userName}}</span>

    <div class="cursor-pointer flex flex-row items-center h-full"
      @click="onToggle"
    >
      <el-icon :class="`mx-4 ${isSelected ? 'text-red-101' : 'text-green-101'}`" :size="45">
        <circle-plus-filled v-if="!isSelected" />
        <circle-close-filled v-if="isSelected" />
      </el-icon>
    </div>
  </div>
</template>

<script>
import {CirclePlusFilled, CircleCloseFilled} from '@element-plus/icons-vue'
import {computed} from 'vue'

export default {
  components: {
    CirclePlusFilled,
    CircleCloseFilled,
  },
  props: {
    user: {type: Object},
    isSelected: {type: Boolean},
    toggleUser: {type: Function},
  },
  setup(props) {
    const userName = computed(() => props.user && props.user.user_display_name);

    const onToggle = () => {
      props.toggleUser && props.toggleUser(props.user);
    };

    return {
      userName,
      onToggle,
    }
  },
}
</script>

<style>

</style>