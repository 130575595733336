<template>
  <div class="flex flex-col w-full rounded-xl border-2 bg-gray-300 p-2 min-h"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0)"
  >
    <div class="flex flex-row items-center justify-between">
      <span class="w-1/2 truncate text-left">{{workName || ''}}</span>

      <div class="flex flex-row items-center">
        <div class="cursor-pointer flex flex-row items-center h-full" @click="onComment"
        >
          <el-icon class="mx-4" :size="45"><comment /></el-icon>
        </div>

        <div class="cursor-pointer flex flex-row items-center h-full" @click="onShare"
          v-if="isAdmin"
        >
          <el-icon class="mx-4" :size="45"><share /></el-icon>
        </div>

        <comp-uploader v-if="isAdmin" :useIcon="true" :workId="workId" />

        <div class="cursor-pointer flex flex-row items-center h-full" @click="onResolve"
          v-if="isAdmin"
        >
          <el-icon :class="`mx-4 ${workResolved ? 'text-green-101' : ''}`" :size="45">
            <success-filled />
          </el-icon>
        </div>
      </div>
    </div>

    <comp-player :src="uri" class="w-full h-auto" v-if="!isImage(uri)" />
    <img :src="uri" class="w-full h-auto"
      v-if="isImage(uri)" :id="`work_${workId}`"
      v-fullscreen="fullScreenOpts"
    />

    <el-dialog v-model="showCommentDialog"
      title="备注"
      destroy-on-close
      top="1%"
      width="80%"
      :append-to-body="true"
    >
      <div class="flex flex-col items-start">
        <div class="flex flex-col w-full border-2 mb-2 p-1 text-sm rounded"
          v-for="c in comments" :key="c.id"
        >
          <span>{{c.user_display_name}} ({{formatTime(c.update_time)}}):</span>
          <span>{{c.comment}}</span>
        </div>

        <el-input placeholder="输入备注"
          v-model="newComment"
          clearable
          size="large"
          class="my-2"
          @change="addCommentByWorkId"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><comment /></el-icon>
          </template>
        </el-input>
      </div>
    </el-dialog>

    <el-dialog v-model="showShareDialog"
      title="分享"
      destroy-on-close
      top="1%"
      width="80%"
      :append-to-body="true"
    >
      <el-collapse v-model="shareActivePane" class="w-full">
        <el-collapse-item title="分享用户" name="1">
          <div class="flex flex-row flex-wrap items-center w-full">
            <comp-user v-for="u in selectedUsers" :key="u.id"
              :user="u" :isSelected="isSelected(u)" :toggleUser="toggleUser"
            />
          </div>
        </el-collapse-item>

        <el-collapse-item title="所有用户" name="2">
          <el-input placeholder="输入用户名"
            v-model="userFilter.userName"
            clearable
            @change="getUsers"
            size="large"
          >
            <template #prefix>
              <el-icon class="el-input__icon"><search /></el-icon>
            </template>
          </el-input>

          <div class="flex flex-row flex-wrap items-center w-full">
            <comp-user v-for="u in allUsers" :key="u.id"
              :user="u" :isSelected="isSelected(u)" :toggleUser="toggleUser"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>

<script>
import compPlayer from '@/components/common/player'
import compUser from './user.vue'
import * as store from './store'
import * as storeUser from '@/components/user/store'
import {onMounted, watch, computed, ref} from 'vue'
import {SuccessFilled, Comment, Share, Search} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import time from '@/lib/time'
import {isImage} from '@/lib/utils'
import compUploader from '@/components/works/uploader'

export default {
  components: {
    compPlayer,
    compUser,
    SuccessFilled,
    Comment,
    Share,
    Search,
    compUploader,
  },
  props: {
    work: {type: Object},
  },
  setup(props) {
    const loading = ref(false);
    const uri =  ref('');
    const workId = computed(() => props.work && props.work.id);
    const workName = computed(() => props.work && props.work.work_name || '');
    const workResolved = computed(() => props.work && props.work.status);
    const isAdmin = computed(() =>
      storeUser.state.userInfo && storeUser.state.userInfo.isAdmin
    );
    const showCommentDialog = ref(false);
    const showShareDialog = ref(false);
    const comments = computed(() =>
      store.state.comments && store.state.comments[workId.value] || []
    );
    const newComment = ref('');
    const fullScreenOpts = computed(() => {
      return {
        target: `#work_${workId.value}`,
      };
    });

    const userFilter = ref({
      userName: '',
    });
    const shareActivePane = ['1', '2'];
    const selectedUsers = computed(() =>
      store.state.users && store.state.users[workId.value] || []
    );
    const allUsers = computed(() => storeUser.state.users || []);

    const streamWorkById = async () => {
      loading.value = true;
      uri.value = await store.actions.streamWorkById(workId.value);
      loading.value = false;
    };

    const onComment = async () => {
      await store.actions.getCommentsByWorkId(workId.value);
      showCommentDialog.value = true;
    };

    const addCommentByWorkId = async () => {
      await store.actions.addCommentByWorkId(workId.value, newComment.value);
      newComment.value = '';
    };

    const getUsers = async () => {
      await storeUser.actions.getUsers(userFilter.value);
    };

    const onShare = async () => {
      await store.actions.getWorkUsersByWorkId(workId.value);
      await storeUser.actions.getUsers(userFilter.value);
      showShareDialog.value = true;
    };

    const shareWorkToUsers = async () => {
      await store.actions.shareWorkToUsers(workId.value, selectedUsers.value);
      return ElMessage({
        type: 'info',
        message: '已提交',
      });
    };

    const isSelected = (user) => {
      const u = selectedUsers.value.find(su => su.id == user.id);
      return !!u;
    };

    const toggleUser = async (user) => {
      store.mutations.toggleUser(workId.value, user);
      await shareWorkToUsers();
    };

    const onResolve = async () => {
      if (!props.work) {
        return;
      }

      const workStatus = props.work.status == 0 ? 1 : 0;
      await store.actions.resolveWorkById(workId.value, workStatus);
      store.mutations.setWorkStatusById(workId.value, workStatus);
      return ElMessage({
        type: 'info',
        message: '已提交',
      });
    };

    const onUpload = async () => {};

    const formatTime = time.formatTime.bind(time);

    onMounted(streamWorkById);
    watch(workId, streamWorkById);

    return {
      loading,
      workId,
      workName,
      uri,
      workResolved,
      isAdmin,
      showCommentDialog,
      showShareDialog,
      comments,
      newComment,
      shareActivePane,
      selectedUsers,
      allUsers,
      userFilter,
      onComment,
      addCommentByWorkId,
      getUsers,
      onShare,
      isSelected,
      toggleUser,
      onResolve,
      onUpload,
      formatTime,
      isImage,
      fullScreenOpts,
    }
  },
}
</script>

<style scoped>
.min-h {
  min-height: 150px;
}
</style>