<template>
  <el-upload
    action="#"
    :drag="true"
    :auto-upload="true"
    :multiple="false"
    :show-file-list="false"
    :http-request="onUpload"
    class="flex flex-row items-center px-2 h-full"
  >
    <div class="flex flex-row items-center h-full">
      <el-button type="primary" v-if="!useIcon">上传素材</el-button>
      <el-icon class="mx-4" :size="45" v-if="useIcon"><circle-plus-filled /></el-icon>
    </div>
  </el-upload>
</template>

<script>
import {CirclePlusFilled} from '@element-plus/icons-vue'
import * as store from './store'

export default {
  components: {
    CirclePlusFilled,
  },
  props: {
    useIcon: {type: Boolean},
    workId: {type: [String, Number]},
  },
  setup(props) {
    const onUpload = async (params) => {
      await store.actions.workUpload(params, props.workId);
    };

    return {
      onUpload,
    }
  },
}
</script>

<style>

</style>