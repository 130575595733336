<template>
  <div class="flex flex-col w-full h-full items-center">
    <div class="flex flex-row items-center w-full">
      <el-input placeholder="输入视频名"
        v-model="filter.workName"
        clearable
        @change="onFilter"
        size="large"
      >
        <template #prefix>
          <el-icon class="el-input__icon"><search /></el-icon>
        </template>
      </el-input>

      <comp-uploader />
    </div>

    <ul v-infinite-scroll="onLoad" class="infinite-list">
      <li v-for="c in works" :key="c.id" class="infinite-list-item">
        <comp-preview :work="c" />
      </li>
    </ul>
  </div>
</template>

<script>
import * as store from './store'
import * as storeBag from '@/lib/store/bag'
import {Search} from '@element-plus/icons-vue'
import compPreview from './preview.vue'
import {watch, computed} from 'vue'
import compUploader from '@/components/works/uploader'

export default {
  components: {
    Search,
    compPreview,
    compUploader,
  },
  setup() {
    const {works} = store.useStore();
    const filter = store.state.filter;
    const collectionId = computed(() =>
      storeBag.state.routeParams &&
      storeBag.state.routeParams.params &&
      storeBag.state.routeParams.params.collectionId
    );

    const onLoad = store.actions.getWorksByCollectionId;

    const onFilter = () => {
      store.mutations.resetWorks();
      return onLoad();
    };

    const onUpload = async () => {};

    watch(collectionId, onFilter);

    return {
      works,
      filter,
      onLoad,
      onFilter,
      onUpload,
    }
  },
}
</script>

<style scoped>
.infinite-list {
  @apply w-full;
  @apply h-full;
  @apply flex-1;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-start;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list-item {
  @apply flex-1;
  @apply flex;
  @apply flex-row;
  @apply items-center;
  @apply justify-center;
  @apply w-full;
  @apply my-5;
}
</style>