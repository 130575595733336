import {reactive, computed} from 'vue'
import req from '@/lib/req'
import apis from '@/assets/apis'
import * as storeBag from '@/lib/store/bag'

export const state = reactive({
  filter: {
    workName: '',
  },
  perPage: 2,
  works: [],
  comments: {},
  users: {},
});

export const mutations = {
  setPage(page) {
    state.page = Number(page) || 1;
  },
  setWorks(obj) {
    const l = state.works.filter(o => o.page < obj.page);
    state.works = [
      ...l,
      obj,
    ];
  },
  resetWorks() {
    state.works = [];
  },
  setWorkStatusById(workId, workStatus) {
    state.works.forEach(o => {
      (o.data || []).forEach(w => {
        if (w.id == workId) {
          w.status = workStatus;
        }
      });
    });
  },
  setComments(workId, comments) {
    state.comments[workId] = comments;
  },
  setWorkUsers(workId, users) {
    state.users[workId] = users || [];
  },
  toggleUser(workId, user) {
    const users = state.users[workId] || [];
    const u = users.find(su => su.id == user.id);

    if (u) {
      state.users[workId] = users.filter(su => su.id != user.id);
    } else {
      state.users[workId] = [...users, user];
    }
  },
}

export const actions = {
  async getWorksByCollectionId() {
    const page = state.works.length + 1;
    const data = await req({
      method: 'POST',
      url: apis.getWorksByCollectionId,
      withCredentials: true,
      data: {
        page,
        perPage: state.perPage,
        collectionId: storeBag.state.routeParams.params.collectionId,
        filter: state.filter,
      },
    });

    const works = data && Array.isArray(data.works) &&
      data.works.length > 0 &&
      data.works || null;
    works && mutations.setWorks({
      page,
      data: works,
    });
  },
  async streamWorkById(workId) {
    const uri = await req({
      method: 'POST',
      url: apis.streamWorkById,
      withCredentials: true,
      data: {
        workId,
      },
    });

    return uri;
  },
  async resolveWorkById(workId, workStatus) {
    await req({
      method: 'POST',
      url: apis.resolveWorkById,
      withCredentials: true,
      data: {
        workId,
        workStatus,
      },
    });
  },
  async getCommentsByWorkId(workId) {
    const comments = await req({
      method: 'POST',
      url: apis.getCommentsByWorkId,
      withCredentials: true,
      data: {
        workId,
      },
    });

    mutations.setComments(workId, comments);
  },
  async addCommentByWorkId(workId, comment) {
    await req({
      method: 'POST',
      url: apis.addCommentByWorkId,
      withCredentials: true,
      data: {
        workId,
        comment,
      },
    });

    return actions.getCommentsByWorkId(workId);
  },
  async getWorkUsersByWorkId(workId) {
    const users = await req({
      method: 'POST',
      url: apis.getWorkUsersByWorkId,
      withCredentials: true,
      data: {
        workId,
      },
    });

    mutations.setWorkUsers(workId, users);
  },
  async shareWorkToUsers(workId, users) {
    await req({
      method: 'POST',
      url: apis.shareWorkToUsers,
      withCredentials: true,
      data: {
        workId,
        users,
      },
    });
  },
  async workUpload(params, workId, onProgress) {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('collectionId', storeBag.state.routeParams.params.collectionId);
    workId && formData.append('workId', workId);
    await req({
      method: 'POST',
      url: apis.workUpload,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data'},
      withCredentials: true,
      onUploadProgress: (event) => {
        if (onProgress) {
          const total = event.total || 1;
          const loaded = event.loaded || 0;
          const p = loaded / total;
          return onProgress(p);
        }
      },
    });

    mutations.resetWorks();
    return actions.getWorksByCollectionId();
  },
}

export function useStore() {
  const works = computed(() => {
    const works = [...state.works];
    works.sort((a, b) => a.page - b.page);

    let ret = [];
    works.forEach(c => {
      ret = [
        ...ret,
        ...c.data,
      ];
    });

    return ret;
  });

  return {
    works,
  };
}